import React, { useEffect, useState } from 'react'

import { Link } from 'gatsby'

import Layout from '../../components/layout'

import lonnie from '../../images/lonnie.png'

import '../../styles/common-styles.css'
import '../../styles/music-styles.css'
import '../../styles/layout-styles.css'
import '../../styles/album-styles.css'

const LonniesPage = () => {
  const [isMobile, setIsMobile] = useState(true)

  useEffect(() => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ]

    let isMobile = toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem)
    })

    setIsMobile(isMobile)
  }, [])

  return (
    <Layout>
      <div className='pageTitle'>Lonnie's Lament</div>
      <hr className='mx-8 mt-2 mb-8 md:my-4 md:mx-64 text-white border-gray-500' />
      {!isMobile && (
        <div className='albumInfoWrapper'>
          <img className='albumInfoImage' src={lonnie} alt='' />
          <div className='albumInfoRight'>
            <div className='albumInfoNotes text-gray-700'>
              <p className='my-4'>
                "Lonnie's Lament", Velvet Brown's homage to jazz, is a labor of
                love. With a musical education rooted in the classical
                tradition, Velvet used to admire jazz, but never approached it
                seriously as a performer until 2002, when Howard Johnson
                contacted her to play lead tuba with his Gravity Jazz Tuba
                Ensemble. Having heard Brown's first solo tuba recording,
                Johnson immediately understood the potential behind her smooth
                and clear articulation and her incredible range, and he took her
                under his tutelage and guided her to become the lead tuba in his
                band. Learning the art of the stage with the likes of Johnson,
                Dave Bargeron, Earl McIntyre, Joe Daley, and Bob Stewart, she
                began exploring jazz. "Lonnie's Lament" is her musical thank you
                to Howard Johnson for having opened a door to her new expressive
                possibilities.
              </p>
              <p className='my-4'>
                Each piece included in this recording tells a story of Velvet's
                life. Friendships, formative moments, and memories of those who
                are no longer here are portrayed in a musical style that allowed
                her to find a renewed voice to share her love for music and for
                people who shaped her personal and artistic life.
              </p>
              <p className='my-4'>Lonnie's Lament will be avalible soon.</p>
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <div className='mobileAlbumInfoWrapper'>
          <img className='mobileAlbumInfoImage' src={lonnie} alt='' />
          <div className='content text-gray-700'>
            <p className='my-4'>
              "Lonnie's Lament", Velvet Brown's homage to jazz, is a labor of
              love. With a musical education rooted in the classical tradition,
              Velvet used to admire jazz, but never approached it seriously as a
              performer until 2002, when Howard Johnson contacted her to play
              lead tuba with his Gravity Jazz Tuba Ensemble. Having heard
              Brown's first solo tuba recording, Johnson immediately understood
              the potential behind her smooth and clear articulation and her
              incredible range, and he took her under his tutelage and guided
              her to become the lead tuba in his band. Learning the art of the
              stage with the likes of Johnson, Dave Bargeron, Earl McIntyre, Joe
              Daley, and Bob Stewart, she began exploring jazz. "Lonnie's
              Lament" is her musical thank you to Howard Johnson for having
              opened a door to her new expressive possibilities.
            </p>
            <p className='my-4'>
              Each piece included in this recording tells a story of Velvet's
              life. Friendships, formative moments, and memories of those who
              are no longer here are portrayed in a musical style that allowed
              her to find a renewed voice to share her love for music and for
              people who shaped her personal and artistic life.
            </p>
            <p className='my-4'>Lonnie's Lament will be avalible soon.</p>
          </div>
        </div>
      )}
      <div
        style={{ marginLeft: '15%', marginTop: '35px', marginBottom: '35px' }}
      >
        <Link to='/music'>←Back to Discography</Link>
      </div>
    </Layout>
  )
}

export default LonniesPage
